import { db } from "./firebase";
import { get, ref, runTransaction } from "firebase/database";

export const incrementMember = (groupId) => {
  const chatMembersRef = ref(db, `/snapchat/${groupId}/members`);
  runTransaction(chatMembersRef, (members) => {
    return members + 1;
  });
};

export const getChatLink = async (groupId) => {
  const chatRef = ref(db, `/snapchat/${groupId}`);
  return await get(chatRef).then((snap) => {
    if (snap.exists()) {
      return snap.val().link;
    } else {
      console.assert(snap.exists());
      return "";
    }
  });
};

export const getSummary = async () => {
  const summaryRef = ref(db, `/snapchat`);
  return await get(summaryRef).then((snap) => {
    if (snap.exists()) {
      const summary = toUiSummary(snap.val());
      return summary;
    } else {
      console.assert(snap.exists());
      return;
    }
  });
};

const toUiSummary = (dbSummary) => {
  const members = [];
  const links = [];
  for (const chat of dbSummary) {
    members.push(chat.members);
    links.push(chat.link);
  }
  return { members, links };
};
