import "./App.css";
import { useEffect } from "react";
import { getSummary, incrementMember } from "./db";

function App() {
  useEffect(() => {
    redirectToSnap();
  }, []);

  const redirectToSnap = async () => {
    const { members, links } = await getSummary();
    if (members[0] < 90) {
      incrementMember(0);
      window.location.replace(links[0]);
    }
    for (let i = 0; i < members.length - 1; ++i) {
      if (members[i] >= 90) {
        incrementMember(i + 1);
        window.location.replace(links[i + 1]);
      }
    }
  };
  return <div className="App"></div>;
}

export default App;
