// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAZO5cjRINo5o_ugdzV9eA2pElKWCiCQ8I",
  authDomain: "bouncy-cb426.firebaseapp.com",
  databaseURL: "https://bouncy-cb426-default-rtdb.firebaseio.com",
  projectId: "bouncy-cb426",
  storageBucket: "bouncy-cb426.appspot.com",
  messagingSenderId: "765245832886",
  appId: "1:765245832886:web:0e7e1c8cb8a1f150043017",
  measurementId: "G-X6FY8EESV9",
};

export default firebaseConfig;
